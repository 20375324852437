<template>
    <div>
        <span class="nav-option" @click="toggleLanguages">{{ currentLang }}</span>
        <div v-if="showLanguages">
            <a 
              v-for="lang in availableLanguages" 
              :key="lang"
              class="nav-option" 
              @click="changeLanguage(lang)">
                {{ lang }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showLanguages: false,
            allLanguages: ['EN', 'DE', 'NL']
        };
    },
    computed: {
        availableLanguages() {
            return this.allLanguages.filter(lang => lang !== this.currentLang);
        }, 
        currentLang() {
            return this.$store.state.currentLang;
        }
    },
    methods: {
        toggleLanguages() {
            this.showLanguages = !this.showLanguages;
        },
        changeLanguage(lang) {
            this.$store.dispatch('changeLanguage', lang); // Dispatch Vuex action
            this.showLanguages = false;
        }
    }
}
</script>

<style scoped>
#languageOptions {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 100; /* Making this high ensures it overlays other elements */
}

.nav-option {
    display: block;
    padding: 8px 15px;
    color: white !important;  /* Make the text color white */
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);  /* Add a subtle shadow */
    transition: color 0.3s;  /* Smooth transition for hover effect */
    cursor: pointer;
    text-decoration: none;

}



.nav-option:hover {
    color: #e0e0e0 !important;  /* Lighter white/grey when hovering */

}

</style>