import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBu_EaTklfpXAuvGgNAZBb6D6kd_iJTizs",
  authDomain: "hydroml.firebaseapp.com",
  projectId: "hydroml",
  storageBucket: "hydroml.appspot.com",
  messagingSenderId: "53968168327",
  appId: "1:53968168327:web:ec6478a1d8bcea84ba9f22",
  measurementId: "G-RDDHF39N0D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

export default db;

