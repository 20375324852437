import { createStore } from 'vuex';

const state = {
  currentLang: 'EN'
};

const getters = {
  getCurrentLang: state => state.currentLang
};

const actions = {
  changeLanguage({ commit }, lang) {
    commit('SET_CURRENT_LANG', lang);
    console.log('Language changed to: ' + lang);
  },
  setInitialLanguage({ commit }, lang) {
    commit('SET_CURRENT_LANG', lang);
},
};

const mutations = {
  SET_CURRENT_LANG(state, lang) {
    state.currentLang = lang;
  }
};

export default createStore({
  state,
  getters,
  actions,
  mutations
});

