<template>
<div id="home" class="bg-image d-flex flex-column justify-content-between"> <!-- Modified classes here -->
    <div class="d-flex justify-content-between mb-4" style="position: relative">  <!-- Added mb-4 for space below -->
        <!-- Logo on the left -->
        <a class="navbar-brand" href="#home">
            <img src="@/assets/logo-no-background.png" alt="Website Logo" width="120">
        </a>

        <!-- Navigation items on the right -->
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link active" href="#cases">{{ translations[userLanguage].MenuCases }}</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="#demo">{{ translations[userLanguage].MenuDemo }}</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="#contact">{{ translations[userLanguage].MenuContact }}</a>
    </li>
        <!-- ... other nav items ... -->
        <li class="nav-item">
            <LanguageSwitcher />
        </li>
  </ul>

    </div>

    <!-- Title -->
    <h1 class="text-white mb-5">{{ translations[userLanguage].MainTitle }}</h1> <!-- Adjust mb-5 as needed for spacing -->

    <!-- Subtitle at the bottom -->
    <h3 class="text-white mb-2">{{ translations[userLanguage].MainSubtitle }}</h3>
</div>



    <div id="cases" class="background-block1">
      <div class="container">
      <div class="row">
        <div class="col-sm">
          <h2 class="text-black mb-5">
            {{ translations[userLanguage].CasesTitle1 }}
          </h2>

        <img src="@/assets/water_cycle.png" class="img-fluid" alt="Responsive image">

        </div>
        <div class="col-sm">
          <div class="content">
            {{ translations[userLanguage].CasesContent1s1 }}
          <br /><br />
      
          {{ translations[userLanguage].CasesContent1s2 }}
        </div>
    </div>  
  </div>
</div>

<div class="container">
    <div class="row">
        <!-- Content Block -->
        <div class="col-sm order-last order-sm-first">
            <div class="content">
              {{ translations[userLanguage].CasesContent2s1 }}  
              <br><br>
              {{ translations[userLanguage].CasesContent2s2 }}
            </div>
        </div>
        <!-- Image and Title Block -->
        <div class="col-sm order-first order-sm-last">
            <h2 class="text-black mb-5">
              {{ translations[userLanguage].CasesTitle2 }}
            </h2>
            <img src="@/assets/Site_laptop_image.png" class="img-fluid" alt="Responsive image">
        </div>
    </div>
</div>

  <div class="container">
      <div class="row">
        <div class="col-sm">
          <h2 class="text-black mb-5">
            {{ translations[userLanguage].CasesTitle3 }}
          </h2>

        <img :src="translations[userLanguage].ImagesBalancing" class="img-fluid" alt="Responsive image">

        </div>
        <div class="col-sm">
          <div class="content">
            {{ translations[userLanguage].CasesContent3s1 }}
             <br /><br />
             {{ translations[userLanguage].CasesContent3s2 }}
            </div>
    </div>  
  </div>
</div>
</div>


<div id="demo" class="background-block2">
    <div class="container">

      <h2 class="text-white mb-5">
                    Demo:
                </h2>
                <div class="responsive-video">
                    <iframe :src="translations[userLanguage].Demo" frameborder="0" allowfullscreen></iframe>


        </div>
    </div>
</div>


<div id="contact" class="background-block1">
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <h2 class="text-black mb-5" id="contact-titel">
                  {{ translations[userLanguage].Contact }}
                </h2>
            </div>
            
            <div class="col-sm" v-if="!formSubmitted">

              <form id="contact-titel" @submit.prevent="checkContact"> 
              <div class="mb-3">
                <input type="text" class="form-control" id="nameInput" v-model="name" :placeholder="translations[userLanguage].ContactName" required>
              </div>

              <div class="mb-3">
                <input type="email" class="form-control" id="emailInput" v-model="email" :placeholder="translations[userLanguage].ContactEmail" required>
              </div>

              <div class="mb-3">
                <textarea class="form-control" id="messageTextarea" v-model="message" rows="4" :placeholder="translations[userLanguage].ContactMessage" required></textarea>
              </div>

              <div class="d-flex justify-content-center">
                <button type="submit" class="btn" style="background-color: #37A556; color: white; padding: 6px 50px; font-size: 1.2em;">{{translations[userLanguage].ContactSubmit}}</button>
              </div>
            </form>

            </div>
            <div class="col-sm" v-else>
              <br />
              <br />
              <p>{{ translations[userLanguage].ContactThanks }}</p>
            </div>
        </div>
    </div>
</div>



</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import translations from '@/i18n/index.js';
import db from '@/firebase/init'
import { collection, addDoc } from "firebase/firestore";

export default {
  name: 'HomeView',
  components: {
        LanguageSwitcher
    },
    data() {
      
    return {
      translations,
      name: '',
      email: '',
      message: '',
      formSubmitted: false,
    };
  },
  created() {
    this.detectUserLanguage();
  },
  computed: {
  userLanguage() {
    return this.$store.state.currentLang;
  }
 },
 methods: {
    async checkContact() {
      try {
        const htmlContent = `Name: ${this.name}<br>Email: ${this.email}<br>Message: ${this.message}`;
        const docRef = await addDoc(collection(db, "mail"), {
          to: 'demo@hydroml.com',
          message: {
            subject: 'Contactverzoek HydroML',
            html: htmlContent,
          }
        });
        this.formSubmitted = true;
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },
    handleScrollTo(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    detectUserLanguage() {
        const browserLang = navigator.language.split('-')[0].toUpperCase();
        const supportedLanguages = ['EN', 'DE', 'NL']; // Add all your supported languages here

        // If the browser's language is supported, use it; otherwise, default to 'EN'
        const initialLanguage = supportedLanguages.includes(browserLang) ? browserLang : 'EN';
        
        // Now set this language in the Vuex store
        this.$store.dispatch('setInitialLanguage', initialLanguage);

    }
  }
}


</script>

<style scoped>
.bg-image {
    background-image: url('@/assets/Background.jpeg'); /* Replace with the path to your image */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh; 
    z-index: -1;
  }
  .background-block1 {
    background-color: #F9F9F9;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
  #contact.background-block1 {
    height: 100vh; 
  }

  .background-block2 {
    background-color: #303030;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; 
    z-index: -1;
  }

  #demo.background-block2 {
    padding-top: 5px;
  }
  #contact-titel {
    margin-top: 40%;
    }
    #contact-content {
      margin-top: 30%;
    }
   
  /* Mobile-specific styles */

  
/* Style for the nav links */
.nav-link {
    color: white !important;  /* Make the text color white */
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);  /* Add a subtle shadow */
    transition: color 0.3s;  /* Smooth transition for hover effect */
}

.nav-link:hover {
    color: #e0e0e0 !important;  /* Lighter white/grey when hovering */

}

.navbar-brand {
  margin-left: 5%;
  padding-top: 2%;
}

h1 {
    font-size: 3.5rem;  /* Example size for the title */
    margin-left: 10%;
    margin-right: 30%;
    margin-top: 20%;
    position: absolute;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);  /* Add a subtle shadow */
}

h2 {
    font-size: 3.5rem;  /* Example size for the title */
    margin: 5%;
}

h3 {
    font-size: 1.5rem;  /* Example size for the subtitle */
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 3%;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);  /* Add a subtle shadow */
}

.content {
  margin-top: 5%;
  color: #3A3A3A;
  font-size: 28px;
}

.responsive-video {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;  /* Aspect ratio for 16:9 */
}

.responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
}

.video-container {
    margin-top: 20vh;    /* 20% of viewport height */
    height: 60vh;       /* 60% of viewport height */
}

@media (max-width: 575.98px) {

.nav-link {
  display: none;
}

#contact-titel {
margin-top: 0%;
}
#contact-content {
  margin-top: 0%;
}
.responsive-video { 
  padding-bottom: 30%;
}
.responsive-video iframe {

height: 100%;
}
h1 {
font-size: 2.5rem;  /* Example size for the title */
margin-left: 5%;
margin-right: 15%;
margin-top: 40%;
text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);  /* Add a subtle shadow */
}

h2 {
font-size: 2.5rem;  /* Example size for the title */
}

h3 {
font-size: 1.3rem;  /* Example size for the subtitle */
margin-left: 5%;
margin-right: 5%;
padding-bottom: 3%;
text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);  /* Add a subtle shadow */
}

}


</style>
