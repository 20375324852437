const translations = {
  EN: {
    MenuCases: "Use cases",
    MenuDemo: "Demo",
    MenuContact: "Contact",
    MainTitle: "Providing explainable AI for the water industry",
    MainSubtitle: "HydroML makes precise future water flow predictions, pump curves and reservoir optimization. This creates business control and minimizes electricity costs",
    CasesTitle1: "Safe water management with accurate machine learning",
    CasesContent1s1: "We tested our future flow prognosis software on various datasets, including drinking water demand, waste water flow and river water flow.",
    CasesContent1s2: "The underlying model automatically recognizes patterns and adds weather data. The resulting prediction resulted in a mean absolute percentage error between 3.5% and 7.25% for the various datasets. The trained model can directly be integrated as an API or dashboard to provide live predictions of the future water flow.",
    CasesTitle2: "Explainable AI with one simple click",
    CasesContent2s1: "As you can see in the demo below, all it takes to train a machine learning model is a list of hourly flow rates. Once the data is uploaded, the model is trained within seconds. In the model dashboard the input feature correlation and importance is presented, together with the individual predictions in a back test. In this way the working of the model is explainable.",
    CasesContent2s2: "We provide additional training when needed, to ensure successful understanding and implementation.",
    CasesTitle3: "Reinforcement learning for reduced energy cost",
    CasesContent3s1: "Reinforcement learning is an algorithm that is mathematically rewarded if it does something according to its goal. For example, if the model succeeds to save energy costs by changing pump schedule patterns, the model is rewarded. In this way the algorithm learn to shift the pumping of water to moments of high electricity availability.",
    CasesContent3s2: "Before applying reinforcement learning,  a digital twin of the pump system is built, including pressure of the water pipes and pump curves. Then the algorithm is used to find the ideal balance between water flow, electricity prices, pressure and pump curves to get the lowest energy cost.",
    Contact: "Feel free to contact us for a free live demo",
    ContactName: "Name",
    ContactEmail: "Email",
    ContactMessage: "Message",
    ContactSubmit: "Send",
    ContactThanks: "We will contact you as soon as possible",

    ImagesBalancing: require("@/assets/Hourly-Load-Balancing-Infographic.png"),
    Demo: "https://www.youtube.com/embed/kD5sPe8lTz4?si=2viL6fL365kD9ep3"

},
  DE: {
    MenuCases: "Anwendungen",
    MenuDemo: "Demo",
    MenuContact: "Kontakt",
    MainTitle: "Erklärbare KI für die Wasserwirtschaft",
    MainSubtitle: "HydroML liefert präzise Vorhersagen der Wasserflussmengen und optimierten Pumpzyklen und Reservoirs. Das schafft Unternehmenskontrolle und minimiert die Stromkosten",
    CasesTitle1: "Sicheres Wasser-management mit präzisen Machine-Learning-Modellen",
    CasesContent1s1: "Wir haben unsere Software zur Prognose der zukünftigen Wassermenge an verschiedenen Datensätzen getestet, darunter Trinkwasserbedarf, Abwassermenge und Flusswassermenge.",
    CasesContent1s2: "Das zugrunde liegende Modell erkennt automatisch Muster und fügt Wetterdaten hinzu. Die daraus resultierende Vorhersage ergab einen mittleren  prozentualen Fehler zwischen 3,5 % und 7,25 % für die verschiedenen Datensätze. Das trainierte Modell kann direkt als API oder Dashboard integriert werden, um Live-Vorhersagen des zukünftigen Wasserflusses zu erstellen.",
    CasesTitle2: "Erklärbare KI mit nur einem Klick",
    CasesContent2s1: "Wie Sie in der untenstehenden Demo sehen können, ist alles, was Sie zum Trainieren eines Lernmodells benötigen, eine Liste mit stündlichen Durchflussraten. Sobald die Daten hochgeladen sind, wird das Modell innerhalb von Sekunden trainiert. Im Dashboard des Modells werden die Korrelation und die Relevanz der Eingangsvariablen zusammen mit den einzelnen Vorhersagen in einem Backtest dargestellt. Auf diese Weise wird die Funktionsweise des Modells erklärbar.",
    CasesContent2s2: "Wir bieten bei Bedarf zusätzliche Schulungen an, um ein umfassendes Verständnis und eine erfolgreiche Umsetzung sicherzustellen.",
    CasesTitle3: "Bestärkendes Lernen zur Senkung der Energiekosten",
    CasesContent3s1: "Bestärkendes Lernen ist ein Algorithmus, der mathematisch eine Variante belohnt, wenn sie auf das erklärte Ziel hinarbeitet. Gelingt es dem Modell beispielsweise, durch eine Änderung der Pumpenschaltzeiten Energiekosten zu sparen, wird dies belohnt. Auf diese Weise lernt der Algorithmus, das Pumpen von Wasser auf Zeiten mit hoher Stromverfügbarkeit zu verlagern.",
    CasesContent3s2: "Vor der Anwendung des bestärkenden Lernens wird ein digitaler Zwilling des Pumpensystems erstellt, einschließlich des Leitungsdrucks und der Pumpenkurven. Dann wird der Algorithmus verwendet, um das ideale Gleichgewicht zwischen Wasserdurchfluss, Strompreisen, Druck und Pumpenkurven zu finden, um die niedrigsten Energiekosten zu erzielen.",
    Contact: "Kontaktieren Sie uns, um eine kostenlose Live-Demo zu erhalten",
    ContactName: "Name",
    ContactEmail: "Email",
    ContactMessage: "Nachricht",
    ContactSubmit: "Absenden",
    ContactThanks: "Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen",

    ImagesBalancing: require("@/assets/Hourly-Load-Balancing-Infographic-DE.png"),
    Demo: "https://www.youtube.com/embed/UYuOWPM9A5o?si=7lj6cAgzvZ7rd_bL"
  },
  NL: {
    MenuCases: "Toepassingen",
    MenuDemo: "Demo",
    MenuContact: "Contact",
    MainTitle: "Kunstmatige intelligentie voor de water industrie",
    MainSubtitle: "HydroML maakt precieze voorspellingen van toekomstige waterdebieten, pomp curves en reservoir optimalisatie. Dit zorgt voor grip op processen en minimaliseert elektriciteitskosten",
    CasesTitle1: "Veilig water management met accurate machine learning modellen",
    CasesContent1s1: "We hebben onze toekomstige waterdebieten software getest op verschillende datasets, waaronder drinkwater vraag, rioolwater en rivierwaterstromen.",
    CasesContent1s2: "Het onderliggende model herkent automatisch patronen en voegt relevante weerdata toe. Het model kreeg het op de verschillende datasets voor elkaar om met een gemiddelde procentuele afwijking (mpe) van tussen de 3.5% en 7.25% te voorspellen. Het getrainde model kan direct worden geïntegreerd als API of gebruikt worden in een dashboard om live voorspellingen over de toekomstige waterstroom te genereren.",
    CasesTitle2: "Verklaarbare AI met één druk op de knop",
    CasesContent2s1: "Zoals je op de demo hieronder kan zien, is er alleen een lijst nodig met historische waterstromen. Als de data geupload is, is het model binnen seconden getraind. In het model dashboard zijn de correlatie en relevantie van de variabelen te zien. Daarnaast wordt een deel van de historische data gebruikt voor een back test. Op deze manier begrijpen we direct waar het model zijn keuzes op baseert.",
    CasesContent2s2: "Door middel van trainingen zorgen we voor eigenaarschap en een succesvolle implementatie.",
    CasesTitle3: "Reinforcement learning om energiekosten te besparen",
    CasesContent3s1: "Reinforcment learning is een algoritme dat wiskundig beloond wordt als het iets doet waardoor het een bepaald doel behaald. Als het model bijvoorbeeld energiekosten bespaart door pompschakelingen te optimaliseren of te wachten tot gunstige elektriciteitsprijzen, krijgt het een beloning. Op deze manier leert het algoritme om de pompen zo gunstig mogelijk aan te sturen.",
    CasesContent3s2: "Voordat reinforcement learning toegepast kan worden, wordt een digitale tweeling gebouwd als simulatieomgeving, inclusief leidingdruk en pomp curves. Het algoritme wordt vervolgens gebruikt om het optimale evenwicht te vinden tussen waterlevering, stroomprijs, druk en pomp curves.",
    Contact: "Neem contact met ons op voor een gratis live demo",
    ContactName: "Naam",
    ContactEmail: "Email",
    ContactMessage: "Bericht",
    ContactSubmit: "Verstuur",
    ContactThanks: "We nemen zo snel mogelijk contact op",

    ImagesBalancing: require("@/assets/Hourly-Load-Balancing-Infographic-NL.png"),
    Demo: "https://www.youtube.com/embed/pJqEaDsz7h8?si=aRrE8kEqu9LkqJdd"
  }
}

export default translations
